export const getActiveDecisionTab = state => state.decisionCenter.activeDecisionTab;

export const getDecisionProviders = state => state.decisionCenter.decisionProviders;

export const getCardTemplates = state => state.decisionCenter.cardTemplates;

export const getSteerageTypes = state => state.decisionCenter.steerageTypes;

export const getCustomSteerageTypes = state => state.decisionCenter.customSteerageTypes;

export const getIntakeInfo = state => state.decisionCenter.intakeInfo;

export const getJourneyLocale = state => state.decisionCenter.journeyLocale;

export const getJourneyInitialAddress = state => state.decisionCenter.journeyInitialAddress;

export const getJourneyAddress = state => state.decisionCenter.journeyAddress;

export const getCustomerRelatedProfiles = state => state.decisionCenter.customerRelatedProfiles;

export const getSelectedCustomerProfile = state => state.decisionCenter.selectedCustomerProfile;

export const getWalletCards = state => state.decisionCenter.walletCards;

export const getCustomerAccessToken = state => state.decisionCenter.customerAccessToken;

export const getCustomerNetworkName = state => state.decisionCenter.customerNetworkName;

export const getActiveDecisionKind = state => state.decisionCenter.activeDecisionKind;

export const getPossibleRewardsPrograms = state => state.decisionCenter.possibleRewardsPrograms;

export const getIsTPAUser = state => state.decisionCenter.isTpaUser;

export const getProviderSearchParams = state => state.decisionCenter.providerSearchParams;

export const getProductHostname = state => state.decisionCenter.productHostname;

export const getSearchContextId = state => state.decisionCenter.searchContextId;

export const getProviderSearchExtraParams = state => state.decisionCenter.providerSearchExtraParams;

export const getDecisionAutofillTemplate = state => state.decisionCenter.decisionAutofillTemplate;

export const getDecisionTemlatesData = state => state.decisionCenter.decisionTemlatesData;

export const getCustomerIntegrations = state => state.decisionCenter.customerIntegrations;
