import {IMAGES_URL, PROVIDER_TYPES, TIMEZONE_DATABASE_NAMES} from './constants';

export const DEFAULT_OPTION = {value: '', label: 'None (default)'};

const ORGANIZATION_IMAGES = {
    defaultUC: 'default-uc.png',
    defaultER: 'default-er.png',
    imaging: 'imaging.png',
    pt: 'pt.jpg'
};

export const ORGANIZATION_IMAGES_OPTIONS = [
    {value: `${IMAGES_URL}/${ORGANIZATION_IMAGES.defaultUC}`, label: 'Default UC'},
    {value: `${IMAGES_URL}/${ORGANIZATION_IMAGES.defaultER}`, label: 'Default ER'},
    {value: `${IMAGES_URL}/${ORGANIZATION_IMAGES.imaging}`, label: 'Imaging'},
    {value: `${IMAGES_URL}/${ORGANIZATION_IMAGES.pt}`, label: 'PT'}
];

const AVATARS = {
    maleDoctor: 'male_doctor.jpg',
    femaleDoctor: 'female_doctor.jpg',
    vision: 'vision.jpg',
    dental: 'dental_b61247.png'
};

export const AVATARS_OPTIONS = [
    {value: `${IMAGES_URL}/${AVATARS.maleDoctor}`, label: 'Male'},
    {value: `${IMAGES_URL}/${AVATARS.femaleDoctor}`, label: 'Female'},
    {value: `${IMAGES_URL}/${AVATARS.vision}`, label: 'Vision'},
    {value: `${IMAGES_URL}/${AVATARS.dental}`, label: 'Dental'}
];

export const GENDER_OPTIONS = [
    {value: null, label: 'None'},
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'}
];

export const PROVIDER_TYPE_OPTIONS = [
    {value: null, label: 'None'},
    {value: PROVIDER_TYPES.organization, label: 'Organization'},
    {value: PROVIDER_TYPES.individual, label: 'Individual'}
];

export const RATING_OPTIONS = [
    {value: null, label: 'N/A'},
    {value: 0.5, label: '0.5'},
    {value: 1, label: '1'},
    {value: 1.5, label: '1.5'},
    {value: 2, label: '2'},
    {value: 2.5, label: '2.5'},
    {value: 3, label: '3'},
    {value: 3.5, label: '3.5'},
    {value: 4, label: '4'},
    {value: 4.5, label: '4.5'},
    {value: 5, label: '5'}
];

export const BAD_OPTIONS = [
    {value: 'Negative reviews', label: 'Negative reviews'},
    {value: 'Wrong address/phone', label: 'Wrong address/phone'},
    {value: 'No info found', label: 'No info found'},
    {value: 'No answer or voicemail', label: 'No answer or voicemail'},
    {value: 'For calls later', label: 'For calls later'},
    {value: 'Network issues', label: 'Network issues'}
];

export const OFFICE_TYPE_OPTIONS = [
    {value: 'hospital', label: 'Hospital'},
    {value: 'freestanding_facility', label: 'Freestanding Facility'},
    {value: 'medical_group', label: 'Medical Group'}
];

export const DISTANCE_OPTIONS = [
    {value: '', label: 'None (default)'},
    {value: 5, label: '5mi'},
    {value: 10, label: '10mi'},
    {value: 20, label: '20mi'},
    {value: 30, label: '30mi'},
    {value: 50, label: '50mi'}
];

export const LAST_UPDATE_OPTIONS = [
    {value: '', label: 'All time (default)'},
    {value: 15, label: '15 days'},
    {value: 30, label: '30 days'},
    {value: 60, label: '60 days'},
    {value: 120, label: '120 days'}
];

export const NETWORK_OPTIONS = [
    {value: true, label: 'In Network'},
    {value: false, label: 'Out of Network'},
    {value: null, label: 'Network not applicable'}
];

export const TIMEZONE_OPTIONS = [
    {value: null, label: 'None'},
    {value: TIMEZONE_DATABASE_NAMES.atlantic, label: 'Atlantic'},
    {value: TIMEZONE_DATABASE_NAMES.alaska, label: 'Alaska'},
    {value: TIMEZONE_DATABASE_NAMES.americanSamoa, label: 'American Samoa'},
    {value: TIMEZONE_DATABASE_NAMES.aleutian, label: 'Aleutian'},
    {value: TIMEZONE_DATABASE_NAMES.arizonaState, label: 'Arizona state'},
    {value: TIMEZONE_DATABASE_NAMES.central, label: 'Central'},
    {value: TIMEZONE_DATABASE_NAMES.chamorro, label: 'Chamorro'},
    {value: TIMEZONE_DATABASE_NAMES.eastern, label: 'Eastern'},
    {value: TIMEZONE_DATABASE_NAMES.mountain, label: 'Mountain'},
    {value: TIMEZONE_DATABASE_NAMES.hawaii, label: 'Hawaii'},
    {value: TIMEZONE_DATABASE_NAMES.pacific, label: 'Pacific'}
];

export const STATE_POSTAL_CODE_OPTIONS = [
    {value: 'AL', label: 'AL (Alabama)'},
    {value: 'AK', label: 'AK (Alaska)'},
    {value: 'AZ', label: 'AZ (Arizona)'},
    {value: 'AR', label: 'AR (Arkansas)'},
    {value: 'CA', label: 'CA (California)'},
    {value: 'CO', label: 'CO (Colorado)'},
    {value: 'CT', label: 'CT (Connecticut)'},
    {value: 'DE', label: 'DE (Delaware)'},
    {value: 'DC', label: 'DC (District of Columbia)'},
    {value: 'FL', label: 'FL (Florida)'},
    {value: 'GA', label: 'GA (Georgia)'},
    {value: 'HI', label: 'HI (Hawaii)'},
    {value: 'ID', label: 'ID (Idaho)'},
    {value: 'IL', label: 'IL (Illinois)'},
    {value: 'IN', label: 'IN (Indiana)'},
    {value: 'IA', label: 'IA (Iowa)'},
    {value: 'KS', label: 'KS (Kansas)'},
    {value: 'KY', label: 'KY (Kentucky)'},
    {value: 'LA', label: 'LA (Louisiana)'},
    {value: 'ME', label: 'ME (Maine)'},
    {value: 'MD', label: 'MD (Maryland)'},
    {value: 'MA', label: 'MA (Massachusetts)'},
    {value: 'MI', label: 'MI (Michigan)'},
    {value: 'MN', label: 'MN (Minnesota)'},
    {value: 'MS', label: 'MS (Mississippi)'},
    {value: 'MO', label: 'MO (Missouri)'},
    {value: 'MT', label: 'MT (Montana)'},
    {value: 'NE', label: 'NE (Nebraska)'},
    {value: 'NV', label: 'NV (Nevada)'},
    {value: 'NH', label: 'NH (New Hampshire)'},
    {value: 'NJ', label: 'NJ (New Jersey)'},
    {value: 'NM', label: 'NM (New Mexico)'},
    {value: 'NY', label: 'NY (New York)'},
    {value: 'NC', label: 'NC (North Carolina)'},
    {value: 'ND', label: 'ND (North Dakota)'},
    {value: 'OH', label: 'OH (Ohio)'},
    {value: 'OK', label: 'OK (Oklahoma)'},
    {value: 'OR', label: 'OR (Oregon)'},
    {value: 'PA', label: 'PA (Pennsylvania)'},
    {value: 'RI', label: 'RI (Rhode Island)'},
    {value: 'SC', label: 'SC (South Carolina)'},
    {value: 'SD', label: 'SD (South Dakota)'},
    {value: 'TN', label: 'TN (Tennessee)'},
    {value: 'TX', label: 'TX (Texas)'},
    {value: 'UT', label: 'UT (Utah)'},
    {value: 'VT', label: 'VT (Vermont)'},
    {value: 'VA', label: 'VA (Virginia)'},
    {value: 'WA', label: 'WA (Washington)'},
    {value: 'WV', label: 'WV (West Virginia)'},
    {value: 'WI', label: 'WI (Wisconsin)'},
    {value: 'WY', label: 'WY (Wyoming)'}
];

export const PROVIDER_CREDENTIAL_OPTIONS = [
    {value: 'MD', label: 'MD - Doctor of Medicine'},
    {value: 'DO', label: 'DO - Doctor of Osteopathic Medicine'},
    {value: 'DDS', label: 'DDS - Doctor of Dental Surgery, Doctor of Dental Science'},
    {value: 'DMD', label: 'DMD - Doctor of Dental Medicine, Doctor of Medical Dentistry'},
    {value: 'OD', label: 'OD - Doctor of Optometry '},
    {value: 'DC', label: 'DC - Doctor of Chiropractic'},
    {value: 'DPM', label: 'DPM - Doctor of Podiatric Medicine'},
    {value: 'DPT', label: 'DPT - Doctor of Physical Therapy'},
    {value: 'AUD', label: 'AuD - Doctor of Audiology'},
    {value: 'APRN', label: 'APRN - Advanced Practice Registered Nurse'},
    {value: 'NP', label: 'NP - Nurse Practitioner'},
    {value: 'RN', label: 'RN - Registered Nurse'},
    {value: 'FNP', label: 'FNP - Family Nurse Practitioner'},
    {value: 'MSN', label: 'MSN - Master of Science in Nursing'},
    {value: 'LCMFT', label: 'LCMFT - Licensed Clinical Marriage and Family Therapist'},
    {value: 'LCMHC', label: 'LCMHC - Licensed Clinical Mental Health Counselor'},
    {value: 'LCPC', label: 'LCPC - Licensed Clinical Professional Counselor'},
    {value: 'LCSW', label: 'LCSW - Licensed Clinical Social Worker'},
    {value: 'RD', label: 'RD - Registered Dietitian'},
    {value: 'RDN', label: 'RDN - Registered Dietitian Nutritionist'},
    {value: 'CD', label: 'CD - Certified Dietitian'},
    {value: 'CDN', label: 'CDN - Certified Dietitian Nutritionist'},
    {value: 'LDN', label: 'LDN - Licensed Dietitian Nutritionist'},
    {value: 'LN', label: 'LN - Licensed Nutritionist'}
];

export const PRE_CERTIFICATION_EVENT_OPTIONS = [
    {value: null, label: 'Select an option..'},
    {value: 'MEMBER', label: 'Member Good Decision'},
    {value: 'HCC', label: 'HCC Better Decision'},
    {value: 'UNKNOWN', label: 'Unknown Decision'}
];

export const AUTOFILL_TEMPLATE_OPTIONS = [
    {value: null, label: 'Select an option..'},
    {value: 'Vālenz - Surgery form template', label: 'Vālenz - Surgery'},
    {value: 'Vālenz - Imaging form template', label: 'Vālenz - Imaging'},
    {value: 'Find Care Redirect form template', label: 'Find Care Redirect', requiresFindCare: true}
];
